import gql from 'graphql-tag'

export default gql`
  query getUser {
    user: getSelf {
      id
      countryCode
      firstName
      displayName
      lastName
      createdAt
      image
      currency
    }
  }
`
