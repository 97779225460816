import gql from 'graphql-tag'

export default gql`
  mutation signUpWithoutPassword(
    $identifier: String!
    $language: WebLanguage
    $timezone: String
    $coupon: String
    $therapistId: String
  ) {
    signUpWithoutPassword(
      identifier: $identifier
      language: $language
      timezone: $timezone
      coupon: $coupon
      therapistId: $therapistId
    )
  }
`
