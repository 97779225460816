import { IncomingMessage } from 'http'
import getConfig from 'next/config'

import loadScript from '@helpers/loadScript'
import getLogger from '@src/Logger'

export const { LOG_ROCKET_ID } = getConfig().publicRuntimeConfig

export const LOG_ROCKET_URL = 'https://cdn.lr-ingest.io/LogRocket.min.js'

interface UserTraits {
  [propName: string]: string | number | boolean
}

class LogRocket {
  isEnabled(isLoggedIn: boolean, req?: IncomingMessage) {
    const userAgent = req ? req.headers['user-agent'] : navigator.userAgent
    // Disable LR in Cypress
    const isAgentEnabled = userAgent && !~userAgent.indexOf('Cypress')
    return LOG_ROCKET_ID && isLoggedIn && isAgentEnabled
  }

  identify(uid: string, traits?: UserTraits) {
    if (typeof window === 'object' && window && window.LogRocket) {
      window.LogRocket.identify(uid, traits)
    }
  }

  init() {
    return loadScript(LOG_ROCKET_URL).then(() => {
      window.LogRocket.init(LOG_ROCKET_ID)
      getLogger().info({ obj: { appID: LOG_ROCKET_ID } }, 'LogRocket loaded')
    })
  }
}

const logRocket = new LogRocket()

export default logRocket
