import gql from 'graphql-tag'

export default gql`
  mutation googleLogin($code: String!) {
    googleLogin(code: $code) {
      token
      isLogin
    }
  }
`
