import gql from 'graphql-tag'

export default gql`
  mutation googleSignUp($code: String!, $language: WebLanguage, $timezone: String, $sourceInput: SourceInput) {
    googleSignUp(code: $code, language: $language, timezone: $timezone, sourceInput: $sourceInput) {
      token
      isLogin
    }
  }
`
