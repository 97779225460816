import { NextPageContext } from 'next'

import { WebLanguage } from '@gql/__generated__'
import { COOKIES, getCookie } from '@helpers/cookies'

const langCurrencyMap = [
  {
    language: 'pl',
    currency: 'PLN',
  },
  {
    language: 'cs',
    currency: 'CZK',
  },
]

/**
 * Returns preferred currency for given language.
 * For languages where there is not just 1 preferred currency, it returns null
 */
export const getCurrencyForLanguage = (language: string): string => {
  let result

  // Often we pass language from i18n.language. There language is set asynchronously.
  if (language) {
    result = langCurrencyMap.find(item => item.language.toUpperCase() === language.toUpperCase())
  }

  if (result) {
    return result.currency
  }

  return null
}

/**
 * Returns currency that fits the best for given language. Fallback to CZK.
 */
export const getCurrencyByLanguage = (language: string): string => {
  return getCurrencyForLanguage(language) || 'CZK'
}

export const getUserCurrency = (language: WebLanguage, ctx?: NextPageContext) => {
  return getCookie(COOKIES.CURRENCY, ctx) || getCurrencyByLanguage(language)
}
