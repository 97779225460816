export const loadScript = function (src: string): Promise<Event> {
  if (typeof document !== 'object' || !document) {
    return Promise.reject()
  }

  const h = document.getElementsByTagName('head')[0]

  if (!h) {
    return Promise.reject()
  }

  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = false

    script.addEventListener('load', resolve, false)
    script.addEventListener('error', reject, false)
    script.src = src
    h.appendChild(script)
  })
}

export default loadScript
