import { NextPageContext } from 'next'
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'

import { AppState } from '@store/Types'
import { sourceOperations } from '@store/modules/Source'
import { getCookie, removeCookie } from '@helpers/cookies'
import getLogger from '@src/Logger'

import * as actions from './Actions'

/**
 * Init source on server. Used for SSR, do not persist (persisted on client)
 */
export const serverInit = (
  ctx: NextPageContext,
): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
  const source = getSource(ctx)

  if (!source) {
    return
  }

  getLogger().info({ obj: { source } }, 'User came through referral source (on server)')
  dispatch(sourceOperations.set(source))
}

/**
 * Init source on client. Persist to Redux.
 */
export const clientInit = (): ThunkAction<void, AppState, null, Action<string>> => async dispatch => {
  const source = getSource()

  if (!source) {
    return
  }

  getLogger().info({ obj: { source } }, 'User came through referral source (on client). Saving in store.')

  removeCookie('setReferralSource')
  dispatch(sourceOperations.set(source))
}

/**
 * Get source from HTTP request.
 * It depends on "referralMiddleware" which extracts source from HTTP request
 * and stores it in req.referralSource or using cookie
 * (so it can be passed over redirects)
 */
const getSource = (ctx?: NextPageContext) => {
  let source = null
  let sourceString = null

  // Get from request directly
  if (ctx && (ctx.req as any).referralSource) {
    source = (ctx.req as any).referralSource
    // Or from cookie (so it works with redirects)
  } else {
    try {
      sourceString = getCookie('setReferralSource', ctx)
      source = sourceString ? JSON.parse(sourceString) : null
    } catch (err) {
      getLogger().warn(
        { err, obj: { setReferralSource: sourceString } },
        'Parsing source passed in "setReferralSource" cookie failed',
      )
    }
  }

  return source
}

export const remove = actions.remove

export const set = actions.set
