import getConfig from 'next/config'

import { WebLanguage } from '@gql/__generated__'

const { ALLOWED_LOCALES } = getConfig().publicRuntimeConfig

export { WebLanguage }

export type Locale = {
  countryIso3: string
  webLanguage: WebLanguage // countryIso2
}

const locales = {
  [WebLanguage.En]: {
    countryIso3: 'USA',
    webLanguage: WebLanguage.En,
    label: 'English',
  },
  [WebLanguage.Cs]: {
    countryIso3: 'CZE',
    webLanguage: WebLanguage.Cs,
    label: 'Česky',
  },
}

export default locales

export const getDefaultLocale = (): Locale => getLocale(ALLOWED_LOCALES[0])

export const getLocale = (webLanguage: WebLanguage): Locale => {
  if (locales[webLanguage] === undefined) {
    throw new Error(`Locale ${webLanguage} not found`)
  }

  return locales[webLanguage]
}

export const findLocale = (webLanguage: WebLanguage): Locale | null => {
  return locales[webLanguage] || null
}

export const getAllowedLocales = () => {
  return ALLOWED_LOCALES.map(webLanguage => locales[webLanguage]).filter(locale => !!locale)
}
