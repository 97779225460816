import { ApolloClient } from 'apollo-client'
import { FetchResult } from 'apollo-link'
import decode from 'jwt-decode'
import { NextPageContext } from 'next'
import { I18n } from 'next-i18next'
import getConfig from 'next/config'
import { IncomingMessage } from 'http'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { format } from 'url'

import links, { linkWithQuery } from '@api/links'
import * as actions from './Actions'
import { BroadcastedUserAction, userActionTypes } from './Actions'
import { AuthKind, CurrentUser, Token, TokenPayload, TokenStateType } from './Types'
import analytics from '@src/Analytics'
import logRocket from '@src/service/LogRocket'
import getLogger, { clearLogger, setLogger } from '@src/Logger'
import { AppState } from '@store/Types'
import LOGOUT from '@gql/mutations/logoutMutation.api'
import LOGIN from '@gql/mutations/loginMutation.api'
import SIGNUP from '@gql/mutations/signupWithoutPasswordMutation.api'
import CREATE_PASSWORD_AND_VERIFY from '@gql/mutations/createPasswordAndVerifyMutation.api'
import GOOGLE_SIGNUP from '@gql/mutations/googleSignUpMutation.api'
import GOOGLE_LOGIN from '@gql/mutations/googleLoginMutation.api'
import VERIFY_RESTORE_HASH_MUTATION from '@src/pages/reset-password/verifyRestoreHashMutation.api'
import CHANGE_PASSWORD_MUTATION from '@src/pages/change-password/changePasswordMutation.api'
import {
  GoogleLoginMutation,
  GoogleLoginMutationVariables,
  GoogleSignUpMutation,
  GoogleSignUpMutationVariables,
  LoginMutation,
  LoginMutationVariables,
  LogoutMutation,
  SignUpMutation,
  SignUpMutationVariables,
  CreatePasswordAndVerifyMutationVariables,
} from '@gql/__generated__'
import { removeAuthToken, setAuthToken as saveToken } from '@helpers/auth'
import { findLocale, WebLanguage } from '@api/locales'
import getCurrentUser from '@lib/getCurrentUser'
import { getCookie, removeCookie, setCookie, COOKIES } from '@helpers/cookies'
import capitalize from '@helpers/capitalize'
import redirect from '@helpers/redirect'
import { sourceOperations, sourceSelectors } from '@store/modules/Source'
import { apiErrorToCode } from '@helpers/api'
import { removeItem } from '@helpers/localStorage'
import storageKey from '@api/storageKeys'
import { assignTherapistToUser } from '@components/therapist/assign/utils'
import { saveFinishedOnboarding } from '@pages/onboarding/Utils'
import { getUserCurrency } from '@service/Currency'
import createBroadcastChannel, { BroadcastChannel } from '@helpers/broadcastChannel'
import sentry from '@src/Sentry'

const { Sentry } = sentry()
const { NODE_CONFIG_ENV } = getConfig().publicRuntimeConfig

let channel: BroadcastChannel<BroadcastedUserAction>
// Prevents hanging process in tests
if (process.browser && NODE_CONFIG_ENV !== 'test') {
  channel = createBroadcastChannel<BroadcastedUserAction>('auth')
  channel.onmessage = msg => {
    // Make hard reload on login / signup / logout
    // Right now we cannot execute side effects using plain actions dispatched on Redux store (like Redux Sagas can do)
    // so making reload is the best solution.
    if (msg.type === userActionTypes.SET_TOKEN || msg.type === userActionTypes.LOGOUT) {
      window.location.reload()
    }
  }
}

export const setCurrentUser = (
  currentUser: CurrentUser,
  req?: IncomingMessage,
): ThunkAction<void, AppState, null, Action<string>> => dispatch => {
  analytics.setUser(currentUser)
  if (logRocket.isEnabled(true, req)) {
    logRocket
      .init()
      .then(() =>
        logRocket.identify(currentUser.id, {
          email: currentUser.identifier,
          roles: currentUser.roles.join(','),
        }),
      )
      .catch(() => {
        getLogger().warn('Loading LogRocket failed')
      })
  }

  dispatch(actions.setCurrentUser(currentUser))
}

export const setToken = (
  token: Token,
  tokenPayload: TokenPayload,
): ThunkAction<void, AppState, null, Action<string>> => dispatch => {
  // Using Sentry user convention here
  // https://docs.sentry.io/enriching-error-data/context/?platform=node#capturing-the-user
  const loggedUser = { id: tokenPayload.id, email: tokenPayload.identifier, token }
  setLogger({ user: loggedUser })
  Sentry.setUser(loggedUser)
  // @TODO set analytics user
  const action = actions.setToken(token, tokenPayload)
  dispatch(action)
  channel && channel.postMessage(action).catch(err => getLogger().warn({ err }, 'Posting setToken() message failed'))
}

const authOperations = {
  [AuthKind.Login]: {
    name: 'login',
    mutation: LOGIN,
  },
  [AuthKind.Signup]: {
    name: 'signUp',
    mutation: SIGNUP,
  },
  [AuthKind.SetPassword]: {
    name: 'updateHashResult',
    mutation: CREATE_PASSWORD_AND_VERIFY,
  },
}

export const authorize = (
  kind: AuthKind,
  values: LoginMutationVariables | SignUpMutationVariables | CreatePasswordAndVerifyMutationVariables,
  i18n: I18n,
  client: ApolloClient<any>,
): ThunkAction<
  Promise<{ result: FetchResult<LoginMutation | SignUpMutation>; token: TokenPayload }>,
  AppState,
  null,
  Action<string>
> => (dispatch, getState) => {
  const variables: LoginMutationVariables | SignUpMutationVariables | CreatePasswordAndVerifyMutationVariables = { ...values }
  const state = getState()
  const source = sourceSelectors.getSelf(state)
  const hasSource = sourceSelectors.hasSource(source)

  // Save user's active locale on signup
  if (kind === AuthKind.Signup) {
    const signUpVariables = variables as SignUpMutationVariables
    removeItem(storageKey.THERAPIST_ID)
    const locale = findLocale(i18n.languages[0] as WebLanguage)
    if (locale) signUpVariables.language = locale.webLanguage
    else
      getLogger().error(
        { obj: { webLanguage: i18n.languages[0] } },
        "User's active locale not found in list of application locales",
      )

    if (hasSource) {
      signUpVariables.sourceInput = source
    } else {
      signUpVariables.sourceInput = {
        fraudCookie: getCookie(COOKIES.FRAUD_COOKIE),
        landingPage: '',
      }
    }
    signUpVariables.sourceInput.signUpCampaign = 'no_campaign' // TODO Refactor - remove
    signUpVariables.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  const operation = authOperations[kind]
  const { name, mutation } = operation

  getLogger().info(
    { obj: { identifier: (variables as LoginMutationVariables).identifier } },
    `${capitalize(kind)} submitted`,
  )

  return client
    .mutate<
      LoginMutation | SignUpMutation,
      LoginMutationVariables | SignUpMutationVariables | CreatePasswordAndVerifyMutationVariables
    >({
      mutation,
      variables,
    })
    .then(async result => {
      const { data } = result || {}
      getLogger().info({ obj: { data, variables } }, `${capitalize(kind)} succeed`)
      if (kind === AuthKind.Signup) {
        analytics.pushEvent('registration-success')
        return { result, token: null }
      }
      if (kind === AuthKind.SetPassword) {
        analytics.pushEvent('registration-verified')
      }

      const token = data[name].token
      const tokenPayload = decode(token)
      await onAfterAuth(kind, token, dispatch, i18n, client)

      return { result, token: tokenPayload }
    })
}

const getGoogleAuthRedirectUrl = (isLogin: boolean, coupon?: string) => {
  if (coupon) {
    return linkWithQuery(links.couponRedeem().pathname, { coupon })
  }

  return isLogin ? links.dashboard : links.home
}

export const googleAuth = (
  kind: AuthKind,
  code: string,
  i18n: I18n,
  client: ApolloClient<any>,
  coupon?: string,
): ThunkAction<void, AppState, null, Action<string>> => async (dispatch, getState) => {
  let variables: GoogleLoginMutationVariables | GoogleSignUpMutationVariables
  try {
    variables = { code }
    const state = getState()
    const source = sourceSelectors.getSelf(state)
    const hasSource = sourceSelectors.hasSource(source)

    // Save user's active locale on signup
    if (kind === AuthKind.Signup) {
      const signUpVariables: GoogleSignUpMutationVariables = variables
      const locale = findLocale(i18n.languages[0] as WebLanguage)
      if (locale) {
        signUpVariables.language = locale.webLanguage
      } else {
        getLogger().error(
          { obj: { webLanguage: i18n.languages[0] } },
          "User's active locale not found in list of application locales",
        )
      }
      if (hasSource) {
        signUpVariables.sourceInput = source
      } else {
        signUpVariables.sourceInput = {
          fraudCookie: getCookie(COOKIES.FRAUD_COOKIE),
          landingPage: '',
        }
      }
      signUpVariables.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    getLogger().info({ obj: { variables } }, `Google ${kind} submitted`)

    const { data } = await client.mutate<GoogleLoginMutation | GoogleSignUpMutation, GoogleLoginMutationVariables | GoogleSignUpMutationVariables>({
      mutation: kind === AuthKind.Signup ? GOOGLE_SIGNUP : GOOGLE_LOGIN,
      variables,
    })
    getLogger().info({ obj: { variables } }, `Google ${kind} succeed`)
    const { token, isLogin } = data[kind === AuthKind.Signup ? 'googleSignUp' : 'googleLogin']
    // Response auth kind can differ from requested kind. For example when user requests
    // Google sign-up but he has been already signed up then auth results to login.
    const responseKind: AuthKind = isLogin ? AuthKind.Login : AuthKind.Signup
    removeCookie(COOKIES.GOOGLE_AUTH)
    await onAfterAuth(responseKind, token, dispatch, i18n, client)
    if (responseKind === AuthKind.Signup) {
      analytics.pushEvent('registration-verified')
    }

    try {
      // Assign therapist after google auth succeed
      await assignTherapistToUser(null, client)
    } catch (err) {
      getLogger().error({ err }, 'Cannot assign therapist to user')
    }

    // Save onboarding result
    await saveFinishedOnboarding(null, client)

    redirect(null, getGoogleAuthRedirectUrl(isLogin, coupon))
  } catch (error) {
    getLogger().error({ err: error, obj: { variables } }, `Google ${kind} failed`)
    const errCode = apiErrorToCode(error)
    removeCookie(COOKIES.GOOGLE_AUTH)
    redirect(
      null,
      `${errCode === 'EMAIL_NOT_FOUND' ? format(links.signup.as) : format(links[kind].as)}?error=${i18n.t([
        `auth.googleSignIn.error.${apiErrorToCode(error)}`,
        'auth.googleSignIn.error.default',
      ])}`,
    )
  }
}

export const logout = (
  client: ApolloClient<any>,
  onReload?: () => void,
): ThunkAction<Promise<FetchResult<LogoutMutation>>, AppState, null, Action<string>> => dispatch => {
  getLogger().info('Logout started')

  return client
    .mutate<LogoutMutation>({
      mutation: LOGOUT,
      update: () => {
        getLogger().info('Logout successful')

        dispatch(actions.logout())
        onAfterLogout()
        // (do nothing in LocRocket logger here. It doesn't allow to remove user identifier.)
        if (onReload) {
          onReload()
        } else {
          window.location.reload()
        }
      },
    })
    .catch(err => {
      getLogger().error({ err }, 'Logout failed')
      throw err
    })
}

export const onAfterLogout = (ctx?: NextPageContext) => {
  removeAuthToken(ctx)
  channel &&
    channel.postMessage(actions.logout()).catch(err => getLogger().warn({ err }, 'Posting "logout" message failed'))
  Sentry.setUser(null)
  clearLogger()
  analytics.setUser(null)
}

export const verifyHash = (
  client: ApolloClient<any>,
  hash: string,
): ThunkAction<Promise<TokenStateType>, AppState, null, Action<string>> => dispatch => {
  getLogger().info('verify hash')
  return client
    .mutate({
      mutation: VERIFY_RESTORE_HASH_MUTATION,
      variables: {
        hash,
      },
    })
    .then(result => {
      const tokenPayload = decode(result.data.verifyRestoreHash.token)
      dispatch(setToken(result.data.verifyRestoreHash.token, tokenPayload))
      removeAuthToken()
      redirect(null, links.changePassword)
      return 'valid' as TokenStateType
    })
    .catch(err => {
      throw err
    })
}

export const changePassword = (
  client: ApolloClient<any>,
  newPassword: string,
  i18n: I18n,
): ThunkAction<Promise<FetchResult<any>>, AppState, null, Action<string>> => dispatch => {
  getLogger().info('change password')
  return client
    .mutate({
      mutation: CHANGE_PASSWORD_MUTATION,
      variables: {
        newPassword,
      },
    })
    .then(async result => {
      await onAfterAuth(AuthKind.ChangePassword, result.data.changePassword.token, dispatch, i18n, client)

      return result
    })
    .catch(err => {
      throw err
    })
}

// tslint:disable-next-line:max-func-args
const onAfterAuth = async (kind: AuthKind, token: string, dispatch, i18n: I18n, client: ApolloClient<any>) => {
  // Save token in cookie and Redux store
  saveToken(token)
  const tokenPayload = decode(token)
  dispatch(setToken(token, tokenPayload))
  const { id, identifier, language, profileIds, roles } = tokenPayload

  // Re-set user's locale
  const locale = findLocale(language)
  if (locale) {
    void i18n.changeLanguage(locale.webLanguage)
  } else {
    getLogger().error(
      { obj: { webLanguage: language } },
      'Web language stored in token not supported by user-client application',
    )
  }

  // Asynchronously reset data from API, don't wait for it
  client
    // Reset all active Apollo queries
    .resetStore()
    .then(() => getLogger().info('Apollo store reset was successful'))
    .catch(err => getLogger().error({ err }, 'Apollo store reset failed'))
    .then(async () => {
      // Update current user in store after login
      // Keep it here so it will not break after page hard-refresh is removed
      const { currentUser } = await getCurrentUser(client)
      const loggedUser = {
        id,
        roles,
        profileIds,
        identifier,
        ...currentUser,
      }
      dispatch(setCurrentUser(loggedUser))

      // Re-set user's currency
      const { currency: userCurrency } = loggedUser
      // eslint-disable-next-line promise/always-return
      const currency = (userCurrency || '').toUpperCase() || getUserCurrency(i18n.languages[0] as WebLanguage)
      client.writeData({
        data: { savedCurrency: currency },
      })
      setCookie(COOKIES.CURRENCY, currency)
    })
    .catch(err => getLogger().error({ err }, 'User data reset failed'))

  // Remove sign-up source after successful sign-up
  if (kind === AuthKind.Signup) {
    dispatch(sourceOperations.remove())
  }

  // Log successful login
  if (kind === AuthKind.Login) {
    analytics.pushEvent('login/finished')
  }
}
