import { SourceState } from './Types'

export enum sourceActionTypes {
  REMOVE = 'source/remove',
  SET = 'source/set',
}

export type SetAction = {
  type: typeof sourceActionTypes.SET
  payload: {
    source: SourceState
  }
}

export type RemoveAction = {
  type: typeof sourceActionTypes.REMOVE
}

export type SourceAction = SetAction | RemoveAction

export const remove = (): RemoveAction => ({
  type: sourceActionTypes.REMOVE,
})

export const set = (source: SourceState): SetAction => ({
  type: sourceActionTypes.SET,
  payload: {
    source,
  },
})
