import {
  LogoutAction,
  SetCurrentUserAction,
  SetTokenAction,
  SetUserTherapistAction,
  UserAction,
  userActionTypes,
} from './Actions'
import { UserState } from './Types'

const initialState: UserState = {
  // @TODO remove
  currentUser: null,
  token: null,
  tokenPayload: null,
  therapistId: null,
  onboardingValues: null,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logout = (state: UserState, action: LogoutAction): UserState => {
  return {
    ...state,
    // @TODO remove
    currentUser: null,
    token: null,
    tokenPayload: null,
    therapistId: null,
    onboardingValues: null,
  }
}

// @TODO remove
const setCurrentUser = (state: UserState, action: SetCurrentUserAction): UserState => {
  return {
    ...state,
    currentUser: action.currentUser,
  }
}

const setToken = (state: UserState, action: SetTokenAction): UserState => {
  return {
    ...state,
    token: action.token,
    tokenPayload: action.tokenPayload,
  }
}

const setUserTherapist = (state: UserState, action: SetUserTherapistAction): UserState => {
  return {
    ...state,
    therapistId: action.therapistId,
    onboardingValues: action.values,
  }
}

const reducer = (state = initialState, action: UserAction): UserState => {
  switch (action.type) {
    case userActionTypes.LOGOUT:
      return logout(state, action)
    case userActionTypes.SET_CURRENT_USER:
      return setCurrentUser(state, action)
    case userActionTypes.SET_TOKEN:
      return setToken(state, action)
    case userActionTypes.SET_USER_THERAPIST:
      return setUserTherapist(state, action)
    default:
      return state
  }
}

export default reducer
