import { UserState, Role, Token, OnboardingValues } from './Types'
import { AppState } from '../../Types'

export const getSelf = (storeState: AppState): UserState => storeState.user

export const isLoggedIn = (state: UserState) => hasAnyOfRoles(state, [Role.User])

export const getIdentifier = (state: UserState) => (state.tokenPayload ? state.tokenPayload.identifier : null)

export const getUserId = (state: UserState) => (state.tokenPayload ? state.tokenPayload.id : null)

export const getRoles = (state: UserState) => (state.tokenPayload ? state.tokenPayload.roles : [])

export const getToken = (state: UserState): Token | null => state.token

export const getTokenPayload = (state: UserState) => state.tokenPayload

export const getProfileIds = (state: UserState): string[] => (state.tokenPayload ? state.tokenPayload.profileIds : [])

export const hasAnyOfRoles = (state: UserState, roles: Role[]): boolean =>
  !!state.tokenPayload && !!roles.filter(role => state.tokenPayload.roles.indexOf(role) >= 0).length

export const isStudent = (state: UserState): boolean => hasAnyOfRoles(state, [Role.Student])

export const isTutor = (state: UserState): boolean => hasAnyOfRoles(state, [Role.Tutor])

export const hasGroup = (state: UserState): boolean => !!state.tokenPayload?.groups?.length

export const getGroupId = (state: UserState): string | null =>
  state.tokenPayload?.groups?.length ? state.tokenPayload?.groups[0]?.id : null

export const getMainRole = (state: UserState) =>
  hasAnyOfRoles(state, [Role.Tutor]) ? Role.Tutor : hasAnyOfRoles(state, [Role.Student]) ? Role.Student : Role.User

export const getTherapistId = (state: UserState): string | null => state.therapistId

export const getOnboardingValues = (state: UserState): OnboardingValues | null => state.onboardingValues
