import { ApolloClient } from 'apollo-client'
import { OnboardingValues } from '@store/modules/User'
import SAVE_QUIZ_MUTATION from '@pages/onboarding/SaveQuizMutation.api'
import { OnboardingQuizSaveMutation, OnboardingQuizSaveMutationVariables } from '@gql/__generated__'
import { getItem, setItem, removeItem } from '@helpers/localStorage'
import storageKey from '@api/storageKeys'
import analytics from '@src/Analytics'
import getLogger from '@src/Logger'

/**
 * Maps FormikWizard's page number to step number
 */
export const getStepByPage = (page: number, pagesPerStep: number[]) => {
  let step = 0
  let pages = 0
  for (let i = 0; i < pagesPerStep.length; i++) {
    const pagesInStep = pagesPerStep[i]
    pages += pagesInStep
    step = i

    if (page < pages) {
      break
    }
  }

  return step
}

export const saveFinishedOnboarding = async (onboardingValues: OnboardingValues = null, client: ApolloClient<any>) => {
  onboardingValues = onboardingValues || JSON.parse(getItem(storageKey.ONBOARDING_VALUES))

  if (!onboardingValues) return

  analytics.pushEvent('quiz/finished', { quiz: onboardingValues })
  try {
    await client.mutate<OnboardingQuizSaveMutation, OnboardingQuizSaveMutationVariables>({
      mutation: SAVE_QUIZ_MUTATION,
      variables: {
        notify: false,
        quizInput: {
          quiz: onboardingValues,
        },
      },
    })
    getLogger().info({ obj: { onboardingValues } }, 'Survey saved')
    removeItem(storageKey.ONBOARDING_VALUES)
    removeItem(storageKey.ONBOARDING_PAGE)
  } catch (err) {
    setItem(storageKey.ONBOARDING_VALUES, JSON.stringify(onboardingValues))
    getLogger().error({ err, obj: { onboardingValues } }, 'Saving onboarding failed')
  }
}
