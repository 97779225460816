import { CurrentUser, Token, TokenPayload, OnboardingValues } from './Types'

// Action types are enum so reducer can infer proper action by its type prop
export enum userActionTypes {
  LOGOUT = 'LOGOUT',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  SET_TOKEN = 'SET_TOKEN',
  SET_USER_THERAPIST = 'SET_USER_THERAPIST',
}

export type SetCurrentUserAction = {
  type: typeof userActionTypes.SET_CURRENT_USER
  currentUser: CurrentUser
}

export type SetTokenAction = {
  type: typeof userActionTypes.SET_TOKEN
  token: Token
  tokenPayload: TokenPayload
}

export type LogoutAction = {
  type: typeof userActionTypes.LOGOUT
}

export type SetUserTherapistAction = {
  type: typeof userActionTypes.SET_USER_THERAPIST
  therapistId: string
  values: OnboardingValues
}

export const setCurrentUser = (currentUser: CurrentUser): SetCurrentUserAction => ({
  type: userActionTypes.SET_CURRENT_USER,
  currentUser,
})

export const logout = (): LogoutAction => ({
  type: userActionTypes.LOGOUT,
})

export const setToken = (token: Token, tokenPayload: TokenPayload): SetTokenAction => ({
  type: userActionTypes.SET_TOKEN,
  token,
  tokenPayload,
})

export const setUserTherapist = (therapistId: string, values: OnboardingValues): SetUserTherapistAction => ({
  type: userActionTypes.SET_USER_THERAPIST,
  therapistId,
  values,
})

export type UserAction = SetCurrentUserAction | SetTokenAction | LogoutAction | SetUserTherapistAction

export type BroadcastedUserAction = SetTokenAction | LogoutAction
