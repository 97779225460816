import nookies from 'nookies'
import { NextPageContext } from 'next'
import getConfig from 'next/config'

const { COOKIE_DOMAIN, COOKIES } = getConfig().publicRuntimeConfig

export const getCookie = (name: string, ctx?: NextPageContext): string => nookies.get(ctx)[name]

export const setCookie = (name: string, value: string, ctx?: NextPageContext): unknown =>
  nookies.set(ctx, name, value, {
    domain: COOKIE_DOMAIN,
    path: '/',
  })

export const removeCookie = (name: string, ctx?: NextPageContext): unknown =>
  nookies.destroy(ctx, name, {
    domain: COOKIE_DOMAIN,
    expires: new Date(1970, 0, 1),
    path: '/',
  })

export { COOKIES }

export const removeLegacyCookies = (ctx?: NextPageContext) => {
  // Force-delete legacy next-i18next cookie
  nookies.destroy(ctx, 'next-i18next', {
    domain: 'www.terap.io',
    expires: new Date(1970, 0, 1),
  })

  // Force-delete legacy token (introduced before 6/2019) for everyone
  // so this cookie doesn't take precedence instead of current one
  // Do not remove, there are still users having this cookie
  // Initially cookie was set without path
  nookies.destroy(ctx, 'token', {
    domain: 'www.terap.io',
    expires: new Date(1970, 0, 1),
  })
  // Then we used path /
  nookies.destroy(ctx, 'token', {
    domain: 'www.terap.io',
    expires: new Date(1970, 0, 1),
    path: '/',
  })

  // Remove unused cookies
  removeCookie('currency', ctx)
  removeCookie('exCurrency', ctx)
}
