import { SourceState } from './Types'
import { AppState } from '../../Types'

export const getSelf = (storeState: AppState): SourceState => storeState.source

/**
 * Checks if source is not empty
 */
export const hasSource = (state: SourceState): boolean =>
  // Check that bits are not null nor undefined (checking undefined due to state migrations)
  state.landingPage != null || state.utm != null || state.referer != null || state.referralUrl != null

export const getReferralUrl = (state: SourceState) => state.referralUrl
export const getUtm = (state: SourceState) => state.utm
