import { BroadcastChannel, BroadcastChannelOptions } from 'broadcast-channel'

import getLogger from '@src/Logger'

/**
 * Creates new BroadcastChannel and handles errors thrown during creation phase
 */
const createBroadcastChannel = <T = any>(name: string, opts?: BroadcastChannelOptions) => {
  const channel = new BroadcastChannel<T>(name, opts)
  // Handle errors that occurs during channel creation
  // See https://github.com/pubkey/broadcast-channel/blob/master/src/broadcast-channel.js#L173
  // @ts-ignore Not part of BroadcastChannel API. This is specific for https://github.com/pubkey/broadcast-channel
  channel._prepP && channel._prepP.catch(err => getLogger().warn({ err }, 'Error when creating broadcast channel'))

  return channel
}

export default createBroadcastChannel

export { BroadcastChannel }
