import { destroyCookie, setCookie } from 'nookies'
import { NextPageContext } from 'next'
import getConfig from 'next/config'
import { COOKIES } from '@helpers/cookies'

const { COOKIE_DOMAIN } = getConfig().publicRuntimeConfig

/**
 * "XYZ123" -> "Bearer XYZ123"
 */
export const tokenToHttpHeader = (token: string) => (token ? `Bearer ${token}` : '')

/**
 * "Bearer XYZ123" -> "XYZ123"
 */
export const httpHeaderToToken = (header: string): string | undefined => {
  const authHeader = typeof header === 'string' ? header.split(' ') : []

  if (authHeader[0] === 'Bearer') {
    return authHeader[1]
  }

  return undefined
}

export const setAuthToken = (token: string, ctx?: NextPageContext): unknown =>
  setCookie(ctx, COOKIES.TOKEN, token, {
    maxAge: 10 * 365 * 24 * 60 * 60,
    domain: COOKIE_DOMAIN,
    path: '/',
  })

export const removeAuthToken = (ctx?: NextPageContext): unknown => {
  // Fallback to delete legacy cookie stored on iPhone
  if (process.browser) {
    document.cookie = 'token=;expires=Thu, 01 Jan 1970 01:00:00 GMT'
  }

  return destroyCookie(ctx, COOKIES.TOKEN, {
    domain: COOKIE_DOMAIN,
    expires: new Date(1970, 0, 1),
    path: '/',
  })
}
