import { ApolloClient } from 'apollo-client'
import getLogger from '@src/Logger'
import { getItem, removeItem } from '@helpers/localStorage'
import { ChangeTherapistMutation, ChangeTherapistMutationVariables } from '@gql/__generated__'
import storageKey from '@api/storageKeys'
import CHANGE_THERAPIST_MUTATION from '@components/therapist/assign/ChangeTherapistMutation.api'
import { useQuery } from '@apollo/react-hooks'
import { GetNotUsedPurchasesQuery } from '@gql/__generated__'
import QUERY from '@components/chat/participantDetails/getNotUsedPurchasesQuery.api'

type TherapistReassignment = {
  canAssign: boolean
  canReassign: boolean
}

/**
 * Expects user to be logged in, assign therapist
 *
 * @param therapistId string | null If therapist is null, value is taken from localStorage
 * @param client Apollo client
 */
export const assignTherapistToUser = async (therapistId: string | null, client: ApolloClient<any>) => {
  getLogger().info('assignTherapistToUser')

  therapistId = therapistId || getItem(storageKey.THERAPIST_ID)

  if (!therapistId) return

  removeItem(storageKey.THERAPIST_ID)

  getLogger().info('assignTherapistToUser - local storage cleanup + therapist mutation')
  // TODO: Use useMutation
  return client.mutate<ChangeTherapistMutation, ChangeTherapistMutationVariables>({
    mutation: CHANGE_THERAPIST_MUTATION,
    variables: {
      tutorId: therapistId,
    },
  })
}

export const canReassignTherapist = (therapistId: string, isLoggedIn: boolean): TherapistReassignment => {
  if (!isLoggedIn) {
    return {
      canAssign: true,
      canReassign: false,
    }
  }
  const { data: { getNotUsedPurchases = [], tutorId: selectedTutorId } = {} } = useQuery<GetNotUsedPurchasesQuery>(
    QUERY,
    {
      fetchPolicy: 'cache-and-network',
    },
  )

  return {
    canAssign: selectedTutorId !== therapistId,
    canReassign: selectedTutorId && getNotUsedPurchases.length < 1,
  }
}
