import { i18n } from '@src/i18n'

export const apiErrorToCode = ({ graphQLErrors }): string => {
  if (graphQLErrors && graphQLErrors[0] && graphQLErrors[0].extensions && graphQLErrors[0].extensions.code) {
    return graphQLErrors[0].extensions.code
  }

  return null
}

export const apiErrorToString = (apiError): string => {
  const code = apiErrorToCode(apiError)
  return i18n.t([`api.messagesByCode.${code}`, `api.messagesByCode.default`])
}
