import { NextPageContext } from 'next'
import Router from 'next/router'
import { format, UrlObject } from 'url'

import getLogger from '../Logger'

type Target =
  | {
      as: UrlObject | string
    }
  | string

const getLocation = (target: Target): string => {
  if (typeof target === 'object' && typeof target.as === 'object') {
    return format(target.as)
  }

  if (typeof target === 'object') {
    return target.as as string
  }

  return target
}

export const redirect = (context: NextPageContext, target: Target) => {
  const location = getLocation(target)

  if (context && context.res) {
    // server
    // 303: "See other"
    if (!context.res.headersSent && !context.res.writableEnded) {
      context.res.writeHead(303, { Location: location })
      context.res.end()
      getLogger().info({ obj: { target } }, 'Server redirect occurred')
    } else {
      getLogger().warn(
        { obj: { target, response: { headersSent: context.res.headersSent, finished: context.res.writableEnded } } },
        "Server couldn't make redirect on given response",
      )
    }
  } else {
    // In the browser, we just pretend like this never even happened ;)
    Router.replace(location)
      .then(() => getLogger().info({ obj: { target } }, 'Browser redirect occurred'))
      .catch(err => getLogger().warn({ err }, 'Browser redirect failed'))
  }
}

export const redirectPermanent = (context: NextPageContext, target: Target): void => {
  const location = getLocation(target)

  if (!context?.res) {
    return
  }

  if (context.res.headersSent || context.res.writableEnded) {
    return
  }

  context.res.writeHead(301, { Location: location })
  context.res.end()
}

export default redirect
