import { RemoveAction, sourceActionTypes, SourceAction, SetAction } from './Actions'
import { SourceState } from './Types'

const initialState: SourceState = {
  fraudCookie: null,
  landingPage: null,
  utm: null,
  referer: null,
  referralUrl: null,
}

const remove = (_state: SourceState, _action: RemoveAction): SourceState => {
  return { ...initialState }
}

const set = (state: SourceState, action: SetAction): SourceState => {
  return action.payload.source
}

const reducer = (state = initialState, action: SourceAction) => {
  switch (action.type) {
    case sourceActionTypes.REMOVE:
      return remove(state, action)
    case sourceActionTypes.SET:
      return set(state, action)
    default:
      // Persisted state migration
      if ('referrerUserSlug' in (state as any)) {
        // @ts-ignore
        const { referrerUserSlug, ...omittedState } = state
        return {
          ...omittedState,
          referralUrl: referrerUserSlug,
        }
      }
      return state
  }
}

export default reducer
