import GET_USER from '@gql/queries/getUserQuery.api'

const getCurrentUser = apolloClient =>
  apolloClient
    .query({
      query: GET_USER,
    })
    .then(({ data }) => {
      return { currentUser: data.user }
    })
    .catch(() => {
      return { currentUser: {} }
    })

export default getCurrentUser
