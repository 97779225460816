import gql from 'graphql-tag'

const getNotUsedPurchases = gql`
  query getNotUsedPurchases {
    getNotUsedPurchases: getNotUsedPurchases {
      id
      status
    }
    tutorId: getAssignedTutorId
  }
`

export default getNotUsedPurchases
